.App {
  background-color: var(--color-bg);
  color: var(--color-text);
  min-height: 100vh;

  &.light {
    color-scheme: light;
  }

  &.dark {
    color-scheme: dark;
  }
}

header {
  position: sticky;
  top: 0;
  display: none;
}

.box-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 60px;
  padding: 0 60px;
}

.box {
  &:nth-child(2n) {
    margin-top: 160px;
  }

  &:nth-child(3n) {
    margin-top: 420px;
  }
}

.box--item {
  height: 400px;
  background-color: #ac7afe;
  margin-bottom: 60px;
  animation: 3s ease-in-out appear;
  animation-timeline: view();
  animation-range: cover 0% contain 30%;
}

@keyframes appear {
  0% {
    opacity: 0;
    scale: 0.8;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}
