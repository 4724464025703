.wrap {
  grid-column: canvas-start / canvas-end;
  grid-row: canvas-v-start / canvas-v-end;
  background: linear-gradient(
    var(--ambianceAngle),
    var(--ambianceStartColor),
    var(--ambianceEndColor)
  );
  position: relative;
  transition: --ambianceStartColor 1s ease-out, --ambianceEndColor 1s ease-out,
    --ambianceAngle 1s ease-out;

  &::before,
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    mix-blend-mode: overlay;
    pointer-events: none;
  }

  /* Pattern Background */
  &::before {
    background-image: url("../../../public/pattern2.png");
    background-size: clamp(200px, 34vw, 400px);
    opacity: 0.3;
    filter: brightness(0.3);
  }

  /* Animated Clouds */
  &::after {
    background-image: url("../../../public/cloud.png");
    background-size: cover;
    opacity: 0.4;
    animation: animateClouds 500s linear infinite;
  }
}

@keyframes animateClouds {
  from {
    background-position: 0%;
  }
  to {
    background-position: 100%;
  }
}

.heading {
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-weight: 500;
  font-family: "Spectral", serif;
}

.sky {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  color: var(--textColor);
  padding: 24px;

  ul {
    display: flex;
    align-items: center;
    gap: 12px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  svg {
    stroke: var(--textColor);
  }

  a {
    display: block;
    padding: var(--size-3);

    &:hover {
      svg {
        fill: color-mix(in srgb, var(--textColor) 30%, transparent);
      }
    }

    &:active {
      svg {
        stroke: var(--accentColor);
      }
    }
  }
}

.noTransition {
  --ambianceAngle: 0deg;
  transition: --ambianceStartColor 0s ease-out, --ambianceEndColor 1s ease-out,
    --ambianceAngle 1s ease-out;
}
