.about {
  text-align: center;
  color: var(--textColor);
  border: 1px solid var(--boxBorderColor);
  border-radius: 12px;
  position: relative;
  padding: clamp(8px, 10vw, 48px) clamp(4px, 4vw, 60px);
  filter: hue-rotate(-8deg);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  transition: all 0.2s ease-out, box-shadow 0.3s ease-out,
    --textColor 0.3s ease-out, --shadowAngle 0.3s ease-out;

  box-shadow: calc(sin(var(--shadowAngle)) * 1px)
      calc(cos(var(--shadowAngle)) * -1px) 1.1px var(--shadowColor),
    calc(sin(var(--shadowAngle)) * 5.8px) calc(cos(var(--shadowAngle)) * -5.8px)
      6.5px -0.4px var(--shadowColor),
    calc(sin(var(--shadowAngle)) * 10.9px)
      calc(cos(var(--shadowAngle)) * -10.9px) 12.3px -0.7px var(--shadowColor),
    calc(sin(var(--shadowAngle)) * 17.9px)
      calc(cos(var(--shadowAngle)) * -17.9px) 20.1px -1.1px var(--shadowColor),
    calc(sin(var(--shadowAngle)) * 28.6px)
      calc(cos(var(--shadowAngle)) * -28.6px) 32.2px -1.4px var(--shadowColor),
    calc(sin(var(--shadowAngle)) * 44.7px)
      calc(cos(var(--shadowAngle)) * -44.7px) 50.3px -1.8px var(--shadowColor),
    calc(sin(var(--shadowAngle)) * 67.9px)
      calc(cos(var(--shadowAngle)) * -67.9px) 76.4px -2.1px var(--shadowColor),
    calc(sin(var(--shadowAngle)) * 100px) calc(cos(var(--shadowAngle)) * -100px)
      112.5px -2.5px var(--shadowColor);
}

.header {
  font-family: "Spectral", serif;
  font-weight: 600;
  font-size: clamp(38px, 4vw, 50px);
  letter-spacing: -1.8px;
  line-height: 1.2em;
  margin-bottom: var(--size-5);
  text-wrap: balance;
}

.tagline {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: clamp(20px, 2vw, 28px);
  text-wrap: balance;
}

.connectLink {
  /* display: inline-block; */
  margin-top: var(--size-5);
  font-weight: 600;
  letter-spacing: 0.2px;
  color: var(--textColor);

  span {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      height: 2px;
      opacity: 0;
      background-color: var(--textColor);
      transition: opacity 0.2s ease;
    }

    &:hover:after {
      opacity: 1;
      transition: opacity 0.2s ease;
    }
    a {
      color: var(--textColor);
      text-decoration: none;
    }

    @media (hover: none) {
      &::after {
        opacity: 1;
      }
    }
  }
}

.hour-0 {
  --shadowAngle: 0deg;
}
.hour-1 {
  --shadowAngle: 15deg;
}
.hour-2 {
  --shadowAngle: 30deg;
}
.hour-3 {
  --shadowAngle: 45deg;
}
.hour-4 {
  --shadowAngle: 60deg;
}
.hour-5 {
  --shadowAngle: 75deg;
}
.hour-6 {
  --shadowAngle: 90deg;
}
.hour-7 {
  --shadowAngle: 105deg;
}
.hour-8 {
  --shadowAngle: 120deg;
}
.hour-9 {
  --shadowAngle: 135deg;
}
.hour-10 {
  --shadowAngle: 150deg;
}
.hour-11 {
  --shadowAngle: 165deg;
}
.hour-12 {
  --shadowAngle: 180deg;
}
.hour-13 {
  --shadowAngle: 195deg;
}
.hour-14 {
  --shadowAngle: 210deg;
}
.hour-15 {
  --shadowAngle: 225deg;
}
.hour-16 {
  --shadowAngle: 240deg;
}
.hour-17 {
  --shadowAngle: 255deg;
}
.hour-18 {
  --shadowAngle: 270deg;
}
.hour-19 {
  --shadowAngle: 285deg;
}
.hour-20 {
  --shadowAngle: 300deg;
}
.hour-21 {
  --shadowAngle: 315deg;
}
.hour-22 {
  --shadowAngle: 330deg;
}
.hour-23 {
  --shadowAngle: 345deg;
}
