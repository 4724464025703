@property --sunColor {
  syntax: "<color>";
  initial-value: yellow;
  inherits: true;
}

@property --glowColor {
  syntax: "<color>";
  initial-value: orange;
  inherits: true;
}

@property --sunOpacity {
  syntax: "<number>";
  initial-value: 1;
  inherits: true;
}

/* .horizon {
  position: absolute;
  bottom: -10svh;
  left: -5.5vw;
  right: -5.5vw;
  height: calc(50% + 10svh);
  background-color: var(--color-bg);
  background: linear-gradient(0, var(--color-bg), transparent);
  backdrop-filter: blur(50px);
} */

.cover {
  --orbitOffset: max(calc(150px - 20vw), 2svw);

  height: 100svh;
  display: grid;
  grid-template-columns:
    [canvas-start] 5vw [orbit-start] 1fr [content-start] clamp(
      300px,
      50vw,
      1200px
    )
    [content-end] 1fr [orbit-end] 5vw [canvas-end];

  grid-template-rows: [canvas-v-start] var(--orbitOffset) [orbit-v-start] 10vw [content-v-start] 1fr [content-v-end] 0 [orbit-v-end] 0 [canvas-v-end];
  overflow: hidden;
}

.contentWrap {
  grid-column-start: content-start;
  grid-column-end: content-end;
  grid-row-start: content-v-start;
  grid-row-end: content-v-end;
  z-index: 10;
}

.sunOrbit {
  --orbit-size: 90vw;

  grid-column-start: orbit-start;
  grid-column-end: orbit-end;
  grid-row-start: orbit-v-start;
  grid-row-end: orbit-v-end;

  position: relative;
  width: var(--orbit-size);
  height: var(--orbit-size);
  border-radius: 50%;
  /* border: 6px solid lightgray; */
  /* margin: 0 auto -50vw; */
}

.sunWrapper {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  transform-origin: calc(var(--orbit-size) / 2) 0;
  position: relative;
  mix-blend-mode: plus-lighter;
  opacity: var(--sunOpacity);

  &:before {
    --glow-offset: 10px;
    --glow-n-offset: -10px;
    --glow-spread: 60px;

    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--glowColor);
    filter: blur(5px);
    border-radius: 50%;
    transform: scale(6.5);
    transition: --glowColor 1s ease-out;
    box-shadow: var(--glow-offset) var(--glow-offset) var(--glow-spread)
        var(--glowColor),
      var(--glow-n-offset) var(--glow-n-offset) var(--glow-spread)
        var(--glowColor),
      var(--glow-n-offset) var(--glow-offset) var(--glow-spread)
        var(--glowColor),
      var(--glow-offset) var(--glow-n-offset) var(--glow-spread)
        var(--glowColor);
  }
}

.sun {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: var(--sunColor);
  filter: blur(5px);
  /* mix-blend-mode: color-dodge; */
  transform: scale(2.5);
  transition: --sunColor 1s ease-out;
}

.slider {
  position: absolute;
  bottom: var(--size-0);
  right: 0;
  left: 0;
  margin: 5vw 10vw;
  padding: 20px;
  display: flex;
  flex-direction: column;

  input {
    --c: var(--accentColor); /* active color */
    --g: 8px; /* the gap */
    --l: 5px; /* line thickness*/
    --s: 25px; /* thumb size*/

    width: 100%;
    height: var(--s); /* needed for Firefox*/
    height: 40px;
    --_c: color-mix(in srgb, var(--c), #000 var(--p, 0%));
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    background-color: none;
    cursor: pointer;
    overflow: hidden;
  }
  input:focus-visible,
  input:hover {
    --p: 25%;
  }
  input:active,
  input:focus-visible {
    --_b: var(--s);
  }

  input[type="range"]::-webkit-slider-runnable-track {
    background: none;
    height: 22px;
  }
  /* chromium */
  input[type="range" i]::-webkit-slider-thumb {
    height: var(--s);
    width: var(--s);
    aspect-ratio: 1;
    border-radius: 50%;
    box-shadow: 0 0 0 var(--_b, var(--l)) inset var(--_c);
    border-image: linear-gradient(90deg, var(--_c) 50%, #ffffff30 0) 0 1 /
      calc(50% - var(--l) / 2) 100vw/0 calc(100vw + var(--g));
    -webkit-appearance: none;
    appearance: none;
    transition: 0.3s;
    background-color: transparent;
  }
  /* Firefox */
  input[type="range"]::-moz-range-thumb {
    height: var(--s);
    width: var(--s);
    background: none;
    border-radius: 50%;
    box-shadow: 0 0 0 var(--_b, var(--l)) inset var(--_c);
    border-image: linear-gradient(90deg, var(--_c) 50%, #ffffff30 0) 0 1 /
      calc(50% - var(--l) / 2) 100vw/0 calc(100vw + var(--g));
    -moz-appearance: none;
    appearance: none;
    transition: 0.3s;
  }
  @supports not (color: color-mix(in srgb, rgb(0, 0, 0), rgb(0, 0, 0))) {
    input {
      --_c: var(--c);
    }
  }
}

.sliderText {
  display: flex;
  justify-content: space-between;
  min-height: var(--size-7);
  margin-top: var(--size-5);

  @media (max-width: 850px) {
    display: grid;
    grid-template-columns: 1fr max-content minmax(195px, max-content) max-content 1fr;
    grid-template-areas: "lmargin left center right rmargin";
    gap: var(--size-5);

    .helperText {
      display: contents;
    }
    .keyboardLeft {
      grid-area: left;
    }
    .keyboardRight {
      grid-area: right;
    }
    .buttonContainer {
      grid-area: center;
      justify-self: center;
    }
    .text {
      display: none;
    }
  }
}
.helperText {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  color: var(--textColor);
}

.keyboardRight,
.keyboardLeft {
  padding: 10px;
  border: var(--border-width) solid var(--textColor);
  border-radius: var(--border-radius);

  svg {
    height: 1em;
    width: 1em;
    fill: var(--textColor);
  }
}

@media (hover: none) {
  .sliderText {
    justify-content: center;
  }
  .helperText {
    display: none;
  }
}

.buttonContainer {
  display: flex;
}

.time {
  align-self: center;
  display: flex;
  justify-content: center;
  font-size: clamp(24px, 3vw, 48px);
  font-family: "Spectral", serif;
  font-weight: 800;
  font-style: italic;
  background-image: linear-gradient(
    to left,
    var(--textGradientStart),
    var(--textGradientEnd)
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  transition: --textGradientStart 0.3 ease-out, --textGradientEnd 0.3s ease-out;
  /* filter: blur(2px) opacity(0.2); */
  /* text-shadow: 1px 1px 5px var(--textColor); */
  /* color: var(--textColor); */
}

button {
  background: none;
  border: var(--border-width) solid var(--textColor);
  border-radius: var(--border-radius);
  padding: 6px 12px;
  color: var(--textColor);
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.2px;

  &:hover {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: color-mix(in srgb, var(--textColor) 30%, transparent);
    cursor: pointer;
  }
  &:active {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background-color: color-mix(
      in srgb,
      var(--textColor) 15%,
      #000 15%,
      transparent
    );
    cursor: pointer;
  }
}

.hour-0 {
  --textColor: var(--neutral-200);
  --accentColor: var(--neutral-500);
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 0;
  --ambianceStartColor: var(--neutral-900);
  --ambianceEndColor: var(--neutral-1000);
  --ambianceAngle: 0deg;
  --shadowColor: rgba(15, 7, 6, 0.3);
  --boxBorderColor: color-mix(in srgb, var(--neutral-700) 20%, transparent);
  --textGradientStart: color-mix(in srgb, var(--neutral-200) 30%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--neutral-100) 50%, transparent);
}

.hour-1 {
  --textColor: var(--blue-100);
  --accentColor: var(--blue-200);
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 0;
  --ambianceStartColor: color-mix(
    in srgb,
    var(--blue-900) 70%,
    var(--neutral-900)
  );
  --ambianceEndColor: var(--neutral-1000);
  --ambianceAngle: 15deg;
  --shadowColor: rgba(15, 7, 6, 0.3);
  --boxBorderColor: color-mix(in srgb, var(--blue-200) 10%, transparent);
  --textGradientStart: color-mix(in srgb, var(--blue-200) 30%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--blue-100) 50%, transparent);
}

.hour-2 {
  --textColor: var(--blue-100);
  --accentColor: var(--blue-500);
  --sunColor: var(--purple-600);
  --glowColor: var(--purple-600);
  --sunOpacity: 0.1;
  --ambianceStartColor: var(--blue-900);
  --ambianceEndColor: var(--neutral-1000);
  --ambianceAngle: 30deg;
  --shadowColor: rgba(15, 7, 6, 0.3);
  --boxBorderColor: color-mix(in srgb, var(--blue-200) 10%, transparent);
  --textGradientStart: color-mix(in srgb, var(--blue-200) 30%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--blue-100) 50%, transparent);
}

.hour-3 {
  --textColor: var(--purple-100);
  --accentColor: var(--purple-500);
  --sunColor: var(--red-800);
  --glowColor: var(--red-800);
  --sunOpacity: 0.2;
  --ambianceStartColor: var(--purple-900);
  --ambianceEndColor: var(--neutral-1000);
  --ambianceAngle: 45deg;
  --shadowColor: rgba(15, 7, 6, 0.3);
  --boxBorderColor: color-mix(in srgb, var(--purple-200) 10%, transparent);
  --textGradientStart: color-mix(in srgb, var(--purple-200) 30%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--purple-100) 50%, transparent);
}

.hour-4 {
  --textColor: var(--red-200);
  --accentColor: var(--red-500);
  --sunColor: var(--red-600);
  --glowColor: var(--yellow-900);
  --sunOpacity: 0.4;
  --ambianceStartColor: var(--red-900);
  --ambianceEndColor: var(--neutral-1000);
  --ambianceAngle: 60deg;
  --shadowColor: rgba(15, 7, 6, 0.3);
  --boxBorderColor: color-mix(in srgb, var(--red-200) 10%, transparent);
  --textGradientStart: color-mix(in srgb, var(--red-300) 30%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--red-200) 50%, transparent);
}

.hour-5 {
  --textColor: var(--red-200);
  --accentColor: var(--red-500);
  --sunColor: var(--red-600);
  --glowColor: #781414;
  --sunOpacity: 0.6;
  --ambianceStartColor: var(--red-800);
  --ambianceEndColor: var(--neutral-1000);
  --ambianceAngle: 75deg;
  --shadowColor: rgba(15, 7, 6, 0.2);
  --boxBorderColor: color-mix(in srgb, var(--red-200) 10%, transparent);
  --textGradientStart: var(--yellow-400);
  --textGradientEnd: var(--red-500);
  --textGradientStart: color-mix(in srgb, var(--red-200) 30%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--red-100) 50%, transparent);
}

.hour-6 {
  --textColor: var(--yellow-100);
  --accentColor: var(--yellow-200);
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 0.9;
  --ambianceStartColor: color-mix(
    in srgb,
    var(--red-500) 50%,
    var(--yellow-500)
  );
  --ambianceEndColor: var(--red-900);
  --ambianceAngle: 90deg;
  --shadowColor: color-mix(in srgb, var(--red-900) 20%, transparent);
  --boxBorderColor: color-mix(in srgb, var(--red-200) 15%, transparent);
  --textGradientStart: color-mix(in srgb, var(--yellow-100) 40%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--red-200) 50%, transparent);
}

.hour-7 {
  --textColor: color-mix(in srgb, var(--yellow-100) 50%, var(--neutral-0));
  --accentColor: var(--yellow-100);
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 1;
  --ambianceStartColor: var(--yellow-500);
  --ambianceEndColor: var(--red-600);
  --ambianceAngle: 105deg;
  --shadowColor: color-mix(in srgb, var(--red-900) 13%, transparent);
  --boxBorderColor: color-mix(in srgb, var(--yellow-200) 20%, transparent);
  --textGradientStart: color-mix(in srgb, var(--yellow-200) 40%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--yellow-100) 50%, transparent);
}

.hour-8 {
  --textColor: var(--yellow-800);
  --accentColor: color-mix(in srgb, var(--yellow-700) 70%, var(--red-400));
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 1;
  --ambianceStartColor: var(--yellow-500);
  --ambianceEndColor: color-mix(in srgb, var(--yellow-200) 50%, var(--red-200));
  --ambianceAngle: 120deg;
  --shadowColor: color-mix(
    in srgb,
    color-mix(in srgb, var(--yellow-900) 30%, var(--red-900)) 10%,
    transparent
  );
  --boxBorderColor: color-mix(in srgb, var(--yellow-100) 30%, transparent);
  --textGradientStart: color-mix(in srgb, var(--red-900) 40%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--yellow-800) 60%, transparent);
}

.hour-9 {
  --textColor: var(--yellow-900);
  --accentColor: color-mix(in srgb, var(--red-400) 50%, var(--red-300));
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 1;
  --ambianceStartColor: var(--yellow-300);
  --ambianceEndColor: var(--red-100);
  --ambianceAngle: 135deg;
  --shadowColor: color-mix(in srgb, var(--red-700) 10%, transparent);
  --boxBorderColor: color-mix(in srgb, var(--yellow-100) 30%, transparent);
  --textGradientStart: color-mix(in srgb, var(--red-900) 50%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--yellow-800) 70%, transparent);
}

.hour-10 {
  --textColor: var(--yellow-900);
  --accentColor: color-mix(in srgb, var(--yellow-400) 30%, var(--blue-500));
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 1;
  --ambianceStartColor: var(--yellow-200);
  --ambianceEndColor: var(--blue-200);
  --ambianceAngle: 150deg;
  --shadowColor: color-mix(in srgb, var(--yellow-600) 15%, transparent);
  --boxBorderColor: color-mix(in srgb, var(--yellow-100) 70%, transparent);
  --textGradientStart: color-mix(in srgb, var(--blue-900) 40%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--blue-800) 50%, transparent);
}

.hour-11 {
  --textColor: var(--blue-900);
  --accentColor: var(--blue-600);
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 1;
  --ambianceStartColor: var(--yellow-100);
  --ambianceEndColor: var(--blue-300);
  --ambianceAngle: 165deg;
  --shadowColor: color-mix(
    in srgb,
    color-mix(in srgb, var(--yellow-800) 40%, var(--blue-800)) 10%,
    transparent
  );
  --boxBorderColor: color-mix(in srgb, var(--yellow-100) 60%, transparent);
  --textGradientStart: color-mix(in srgb, var(--blue-700) 50%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--blue-800) 60%, transparent);
}

.hour-12 {
  --textColor: var(--blue-900);
  --accentColor: var(--blue-700);
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 1;
  --ambianceStartColor: color-mix(
    in srgb,
    var(--yellow-100) 20%,
    var(--neutral-0)
  );
  --ambianceEndColor: var(--blue-400);
  --ambianceAngle: 180deg;
  --shadowColor: color-mix(in srgb, var(--blue-900) 10%, transparent);
  --boxBorderColor: color-mix(in srgb, var(--neutral-0) 100%, transparent);
  --textGradientStart: color-mix(in srgb, var(--blue-800) 50%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--blue-900) 60%, transparent);
}

.hour-13 {
  --textColor: var(--neutral-900);
  --accentColor: var(--blue-600);
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 1;
  --ambianceStartColor: var(--green-100);
  --ambianceEndColor: var(--blue-300);
  --ambianceAngle: 195deg;
  --shadowColor: color-mix(in srgb, var(--blue-900) 8%, transparent);
  --boxBorderColor: color-mix(in srgb, var(--neutral-0) 50%, transparent);
  --textGradientStart: color-mix(in srgb, var(--blue-800) 50%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--blue-900) 60%, transparent);
}

.hour-14 {
  --textColor: var(--green-800);
  --accentColor: color-mix(in srgb, var(--green-500) 50%, var(--blue-500));
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 1;
  --ambianceStartColor: var(--green-200);
  --ambianceEndColor: var(--blue-200);
  --ambianceAngle: 210deg;
  --shadowColor: color-mix(in srgb, var(--blue-800) 10%, transparent);
  --boxBorderColor: color-mix(in srgb, var(--green-100) 30%, transparent);
  --textGradientStart: color-mix(in srgb, var(--green-800) 50%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--green-900) 60%, transparent);
}

.hour-15 {
  --textColor: var(--green-900);
  --accentColor: color-mix(in srgb, var(--blue-600) 50%, var(--green-600));
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 1;
  --ambianceStartColor: var(--green-300);
  --ambianceEndColor: var(--blue-300);
  --ambianceAngle: 225deg;
  --shadowColor: color-mix(in srgb, var(--blue-900) 10%, transparent);
  --boxBorderColor: color-mix(in srgb, var(--green-100) 30%, transparent);
  --textGradientStart: color-mix(in srgb, var(--green-700) 50%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--green-800) 60%, transparent);
}

.hour-16 {
  --textColor: var(--purple-800);
  --accentColor: var(--purple-600);
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 1;
  --ambianceStartColor: var(--green-300);
  --ambianceEndColor: var(--purple-400);
  --ambianceAngle: 240deg;
  --shadowColor: color-mix(in srgb, var(--purple-800) 15%, transparent);
  --boxBorderColor: color-mix(in srgb, var(--purple-100) 30%, transparent);
  --textGradientStart: color-mix(in srgb, var(--purple-700) 50%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--purple-800) 60%, transparent);
}

.hour-17 {
  --textColor: color-mix(in srgb, var(--purple-100) 50%, var(--neutral-0));
  --accentColor: color-mix(in srgb, var(--purple-700) 50%, var(--green-700));
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 0.9;
  --ambianceStartColor: var(--green-600);
  --ambianceEndColor: var(--purple-500);
  --ambianceAngle: 255deg;
  --shadowColor: color-mix(in srgb, var(--purple-900) 10%, transparent);
  --boxBorderColor: color-mix(in srgb, var(--purple-100) 15%, transparent);
  --textGradientStart: color-mix(in srgb, var(--purple-200) 50%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--purple-100) 60%, transparent);
}

.hour-18 {
  --textColor: var(--purple-100);
  --accentColor: color-mix(in srgb, var(--purple-300) 30%, var(--yellow-300));
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 0.6;
  --ambianceStartColor: var(--yellow-700);
  --ambianceEndColor: var(--purple-700);
  --ambianceAngle: 270deg;
  --shadowColor: color-mix(in srgb, var(--purple-900) 20%, transparent);
  --boxBorderColor: color-mix(in srgb, var(--purple-100) 10%, transparent);
  --textGradientStart: color-mix(in srgb, var(--purple-300) 50%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--purple-200) 60%, transparent);
}

.hour-19 {
  --textColor: var(--purple-100);
  --accentColor: color-mix(in srgb, var(--purple-500) 30%, var(--red-400));
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 0.4;
  --ambianceStartColor: color-mix(
    in srgb,
    var(--purple-800) 70%,
    var(--yellow-700)
  );
  --ambianceEndColor: color-mix(
    in srgb,
    var(--blue-800) 30%,
    var(--purple-800)
  );
  --ambianceAngle: 285deg;
  --shadowColor: color-mix(in srgb, var(--purple-900) 20%, transparent);
  --boxBorderColor: color-mix(in srgb, var(--purple-200) 10%, transparent);
  --textGradientStart: color-mix(in srgb, var(--red-100) 50%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--purple-200) 60%, transparent);
}

.hour-20 {
  --textColor: var(--purple-200);
  --accentColor: color-mix(in srgb, var(--red-300) 50%, var(--purple-700));
  --sunColor: var(--yellow-500);
  --glowColor: var(--yellow-500);
  --sunOpacity: 0.2;
  --ambianceStartColor: color-mix(
    in srgb,
    var(--purple-900) 50%,
    var(--red-900)
  );
  --ambianceEndColor: var(--blue-900);
  --ambianceAngle: 300deg;
  --shadowColor: rgba(15, 7, 6, 0.15);
  --boxBorderColor: color-mix(in srgb, var(--purple-200) 10%, transparent);
  --textGradientStart: color-mix(in srgb, var(--red-100) 50%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--purple-200) 60%, transparent);
}

.hour-21 {
  --textColor: var(--purple-200);
  --accentColor: color-mix(in srgb, var(--red-700) 50%, var(--purple-300));
  --sunColor: var(--yellow-500);
  --glowColor: var(--yellow-500);
  --sunOpacity: 0.15;
  --ambianceStartColor: color-mix(
    in srgb,
    var(--purple-900) 90%,
    var(--red-900)
  );
  --ambianceEndColor: color-mix(
    in srgb,
    var(--purple-900) 50%,
    var(--blue-900)
  );
  --ambianceAngle: 315deg;
  --shadowColor: rgba(15, 7, 6, 0.15);
  --boxBorderColor: color-mix(in srgb, var(--purple-200) 10%, transparent);
  --textGradientStart: color-mix(in srgb, var(--red-100) 50%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--purple-200) 60%, transparent);
}

.hour-22 {
  --textColor: var(--purple-200);
  --accentColor: var(--purple-500);
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 0;
  --ambianceStartColor: color-mix(
    in srgb,
    var(--purple-900) 90%,
    var(--red-900)
  );
  --ambianceEndColor: color-mix(
    in srgb,
    var(--purple-900) 20%,
    var(--neutral-900)
  );
  --ambianceAngle: 330deg;
  --shadowColor: rgba(15, 7, 6, 0.15);
  --boxBorderColor: color-mix(in srgb, var(--purple-500) 10%, transparent);
  --textGradientStart: color-mix(in srgb, var(--purple-300) 30%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--purple-200) 40%, transparent);
}

.hour-23 {
  --textColor: var(--neutral-200);
  --accentColor: color-mix(in srgb, var(--purple-400) 50%, var(--neutral-400));
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 0;
  --ambianceStartColor: color-mix(
    in srgb,
    var(--purple-900) 70%,
    var(--neutral-900)
  );
  --ambianceEndColor: var(--neutral-1000);
  --ambianceAngle: 345deg;
  --shadowColor: rgba(15, 7, 6, 0.3);
  --boxBorderColor: color-mix(in srgb, var(--purple-400) 10%, transparent);
  --textGradientStart: color-mix(in srgb, var(--purple-200) 30%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--purple-100) 40%, transparent);
}

.hour-24 {
  --textColor: var(--neutral-200);
  --accentColor: var(--neutral-500);
  --sunColor: var(--red-600);
  --glowColor: var(--red-800);
  --sunOpacity: 0;
  --ambianceStartColor: var(--neutral-900);
  --ambianceEndColor: var(--neutral-1000);
  --ambianceAngle: 360deg;
  --shadowColor: rgba(15, 7, 6, 0.3);
  --boxBorderColor: color-mix(in srgb, var(--neutral-700) 20%, transparent);
  --textGradientStart: color-mix(in srgb, var(--neutral-200) 30%, transparent);
  --textGradientEnd: color-mix(in srgb, var(--neutral-100) 50%, transparent);
}
