:root {
  /*  
        Accessibility Color Palette
        https://accessiblepalette.com/?lightness=97,92,84,74,64,54,44,34,24,14&f76f9a=1,4&fe6f5c=0,-5&fec07a=0,-10&63ddcb=0,-3&48acf0=0,3&ac7afe=1,7&232527=1,0
    */

  --neutral-0: #ffffff;
  --neutral-50: #f6f6f6;
  --neutral-100: #e8e8e8;
  --neutral-200: #d1d2d2;
  --neutral-300: #b5b6b7;
  --neutral-400: #9a9b9c;
  --neutral-500: #808183;
  --neutral-600: #67686a;
  --neutral-700: #4e5052;
  --neutral-800: #37393b;
  --neutral-900: #222426;
  --neutral-1000: #151414;

  --purple-50: #faf4ff;
  --purple-100: #f1e3ff;
  --purple-200: #e3c7fe;
  --purple-300: #d0a3fc;
  --purple-400: #bc7ff9;
  --purple-500: #a067d6;
  --purple-600: #8253aa;
  --purple-700: #654080;
  --purple-800: #482f59;
  --purple-900: #2c1e34;

  --blue-50: hsl(204, 88%, 97%);
  --blue-100: hsl(206, 87%, 91%);
  --blue-200: hsl(204, 85%, 82%);
  --blue-300: hsl(205, 86%, 70%);
  --blue-400: hsl(205, 73%, 59%);
  --blue-500: hsl(205, 51%, 49%);
  --blue-600: hsl(206, 51%, 40%);
  --blue-700: hsl(206, 51%, 31%);
  --blue-800: hsl(206, 50%, 22%);
  --blue-900: hsl(207, 51%, 14%);

  --green-50: #e9faf7;
  --green-100: #c2f2ea;
  --green-200: #7fe3d3;
  --green-300: #5bc8b6;
  --green-400: #4eaa9b;
  --green-500: #418e81;
  --green-600: #357268;
  --green-700: #295850;
  --green-800: #1d3f39;
  --green-900: #122723;

  --yellow-50: hsl(31, 100%, 96%);
  --yellow-100: hsl(32, 100%, 89%);
  --yellow-200: hsl(31, 100%, 77%);
  --yellow-300: hsl(29, 71%, 67%);
  --yellow-400: hsl(29, 47%, 57%);
  --yellow-500: hsl(28, 35%, 48%);
  --yellow-600: hsl(27, 35%, 39%);
  --yellow-700: hsl(27, 35%, 30%);
  --yellow-800: hsl(27, 35%, 22%);
  --yellow-900: hsl(28, 35%, 13%);

  --red-50: #fff4f3;
  --red-100: #ffe2de;
  --red-200: #ffc3bd;
  --red-300: #ff9b91;
  --red-400: #fe6d60;
  --red-500: #d45a51;
  --red-600: #ab4942;
  --red-700: #843833;
  --red-800: #5f2825;
  --red-900: #3c1918;

  --pink-50: #fff4f6;
  --pink-100: #ffe1e8;
  --pink-200: #ffc1d0;
  --pink-300: #fe98b2;
  --pink-400: #f56e95;
  --pink-500: #cb5d7c;
  --pink-600: #a24c63;
  --pink-700: #7c3c4c;
  --pink-800: #572c36;
  --pink-900: #341d22;

  --size-0: 0;
  --size-1: 1px;
  --size-2: 2px;
  --size-3: 4px;
  --size-4: 8px;
  --size-5: 16px;
  --size-6: 24px;
  --size-7: 32px;
  --size-8: 40px;
  --size-9: 48px;
  --size-10: 56px;
  --size-11: 64px;
  --size-12: 72px;

  --border-width: var(--size-2);
  --border-radius: var(--size-3);

  /* color-scheme: light dark;

  --color-bg: light-dark(var(--neutral-50), var(--neutral-900));
  --color-surface-1: light-dark(var(--neutral-200), var(--neutral-800));
  --color-surface-2: light-dark(var(--neutral-300), var(--neutral-700));
  --color-surface-3: light-dark(var(--neutral-400), var(--neutral-600));

  --color-text: light-dark(var(--neutral-1000), var(--neutral-0));
  --color-text-subtle: light-dark(var(--neutral-700), var(--neutral-300)); */
}

@property --textColor {
  syntax: "<color>";
  initial-value: hsl(27, 35%, 22%);
  inherits: true;
}

@property --accentColor {
  syntax: "<color>";
  initial-value: #a067d6;
  inherits: true;
}

@property --ambianceStartColor {
  syntax: "<color>";
  initial-value: transparent;
  inherits: true;
}

@property --ambianceEndColor {
  syntax: "<color>";
  initial-value: transparent;
  inherits: true;
}

@property --ambianceAngle {
  syntax: "<angle>";
  initial-value: 45deg;
  inherits: true;
}

@property --shadowColor {
  syntax: "<color>";
  initial-value: rgba(23, 23, 23, 0.1);
  inherits: true;
}

@property --shadowAngle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: true;
}

@property --boxBorderColor {
  syntax: "<color>";
  initial-value: rgba(255, 255, 255, 0.3);
  inherits: true;
}

@property --textGradientStart {
  syntax: "<color>";
  initial-value: white;
  inherits: true;
}

@property --textGradientEnd {
  syntax: "<color>";
  initial-value: white;
  inherits: true;
}
