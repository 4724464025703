@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.spectral-extralight {
  font-family: "Spectral", serif;
  font-weight: 200;
  font-style: normal;
}

.spectral-light {
  font-family: "Spectral", serif;
  font-weight: 300;
  font-style: normal;
}

.spectral-regular {
  font-family: "Spectral", serif;
  font-weight: 400;
  font-style: normal;
}

.spectral-medium {
  font-family: "Spectral", serif;
  font-weight: 500;
  font-style: normal;
}

.spectral-semibold {
  font-family: "Spectral", serif;
  font-weight: 600;
  font-style: normal;
}

.spectral-bold {
  font-family: "Spectral", serif;
  font-weight: 700;
  font-style: normal;
}

.spectral-extrabold {
  font-family: "Spectral", serif;
  font-weight: 800;
  font-style: normal;
}

.spectral-extralight-italic {
  font-family: "Spectral", serif;
  font-weight: 200;
  font-style: italic;
}

.spectral-light-italic {
  font-family: "Spectral", serif;
  font-weight: 300;
  font-style: italic;
}

.spectral-regular-italic {
  font-family: "Spectral", serif;
  font-weight: 400;
  font-style: italic;
}

.spectral-medium-italic {
  font-family: "Spectral", serif;
  font-weight: 500;
  font-style: italic;
}

.spectral-semibold-italic {
  font-family: "Spectral", serif;
  font-weight: 600;
  font-style: italic;
}

.spectral-bold-italic {
  font-family: "Spectral", serif;
  font-weight: 700;
  font-style: italic;
}

.spectral-extrabold-italic {
  font-family: "Spectral", serif;
  font-weight: 800;
  font-style: italic;
}

.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}
